<template>
  <app-side-bar
    v-model="open"
    title="Bedingung bearbeiten"
    width="500"
  >
    <template #actions>
      <v-btn
        v-if="requirement.id"
        icon
        variant="flat"
        data-cy="deleteRequirement"
        @click.stop="deleteRequirement(requirement)"
        ><v-icon color="error">mdi-delete</v-icon></v-btn
      >
      <v-btn
        icon
        data-cy="saveRequirement"
        @click="saveRequirement"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
    <v-form>
      <v-container>
        <v-row>
          <v-col
            v-if="!experienceOnly"
            cols="12"
          >
            <v-text-field
              v-model.number="requirement.minimum_users"
              label="Mindestanzahl AiWs"
              data-cy="minimumUsers"
              variant="outlined"
              type="number"
              step="1"
              placeholder="Alle Personen"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.number="requirement.minimum_months"
              label="Mindesterfahrung"
              data-cy="minimumMonths"
              variant="outlined"
              suffix="Monate"
              type="number"
              step="1"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row v-if="!specialityOnly">
          <v-col class="pb-0">
            <v-tabs
              v-model="tab"
              grow
              direction="vertical"
              color="primary"
              data-cy="requirementType"
            >
              <template v-if="getPremiumFeatures().includes('INTERNAL_ROTATION')">
                <v-tab value="unit">Einheiten</v-tab>
                <v-tab value="rotationType">Einheitstypen</v-tab>
              </template>
              <v-tab value="speciality">Fachrichtungen</v-tab>
              <v-tab
                v-if="!hideExternal"
                value="facility"
                >Rotationen</v-tab
              >
              <v-tab value="label">Label</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-window v-model="tab">
              <v-window-item
                eager
                value="unit"
              >
                <v-row>
                  <v-col>
                    <select-unit
                      v-model="requirement.modelUnits"
                      class="mt-2"
                      :facility-id="facilityId"
                      multiple
                      chips
                      label="Einheit"
                      variant="outlined"
                      data-cy="selectModelUnits"
                      hide-details="auto"
                      return-object
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item
                eager
                value="rotationType"
              >
                <v-row>
                  <v-col>
                    <select-rotation-type
                      v-model="requirement.rotation_types"
                      class="mt-2"
                      label="Einheitstyp"
                      data-cy="selectRotationType"
                      variant="outlined"
                      :facility-id="facilityId"
                      hide-details="auto"
                      multiple
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item
                eager
                value="speciality"
              >
                <v-row>
                  <v-col>
                    <select-speciality
                      v-model="requirement.modelSpecialities"
                      class="mt-2"
                      label="Fachrichtungen"
                      variant="outlined"
                      data-cy="selectModelSpecialities"
                      hide-details="auto"
                      multiple
                      return-object
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item
                eager
                value="facility"
              >
                <v-row>
                  <v-col>
                    <select-facility
                      v-if="!hideExternal"
                      v-model:selected="requirement.modelFacilities"
                      class="mt-2"
                      label="Klinik"
                      variant="outlined"
                      data-cy="selectModelFacilities"
                      hide-details="auto"
                      selectable
                      return-object
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item
                eager
                value="label"
              >
                <v-row>
                  <v-col>
                    <select-label
                      v-model="requirement.labelId"
                      :facility-id="facilityId"
                      class="mt-2"
                      label="Label"
                      data-cy="selectLabel"
                      variant="outlined"
                      hide-details="auto"
                      selectable
                      clearable
                      return-object
                      @update:model-value="updateLabel"
                    />
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="!hideGroup"
            cols="12"
          >
            <v-text-field
              v-model.number="requirement.group"
              label="Gruppe"
              variant="outlined"
              data-cy="group"
              type="number"
              step="1"
              hide-details="auto"
            />
          </v-col>
          <v-divider
            v-if="!experienceOnly"
            class="my-2"
          />
          <v-col
            v-if="!experienceOnly"
            cols="12"
          >
            <v-text-field
              v-model.number="requirement.minimum_percentage"
              label="Arbeitsmodell (mindestens)"
              variant="outlined"
              data-cy="minimumPercentage"
              type="number"
              suffix="%"
              step="1"
              hide-details="auto"
            />
          </v-col>
          <v-col
            v-if="!experienceOnly"
            cols="12"
          >
            <v-text-field
              v-model.number="requirement.minimum_duration"
              label="Mindestaufenthaltsdauer"
              variant="outlined"
              data-cy="minimumDuration"
              type="number"
              suffix="Monate"
              step="1"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row v-if="!specialityOnly">
          <v-col cols="12">
            <v-alert
              class="mb-8"
              border="start"
              type="info"
              color="primary"
              hide-details="auto"
              variant="tonal"
            >
              <div v-html="getRequirementHintText(requirement)" />
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </app-side-bar>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    collection: {
      type: String,
      required: true,
    },
    item: {
      type: [String, Number],
      required: true,
    },
    facilityId: {
      type: String,
      default: null,
    },
    experienceOnly: {
      type: Boolean,
    },
    specialityOnly: {
      type: Boolean,
    },
    hideExternal: {
      type: Boolean,
    },
    hideGroup: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      requirement: {},
      tab: 'unit',
    }
  },
  computed: {
    open: {
      get() {
        return this.modelValue !== null
      },
      set(value) {
        if (!value) {
          this.$emit('update:modelValue', null)
        }
      },
    },
  },
  watch: {
    modelValue(value) {
      if (value) {
        this.requirement = {
          ...value,
          labelId: value.label?.id,
          modelUnits: value.units?.map((unit) => unit.unit_id),
          modelSpecialities: value.specialities?.map((speciality) => speciality.speciality_id),
          modelFacilities: value.facilities?.map((facility) => facility.facility_id),
        }
        if (this.specialityOnly) {
          this.tab = 'speciality'
        } else {
          if (this.requirement.rotation_types?.length) {
            this.tab = 'rotationType'
          }
          if (this.requirement.modelSpecialities?.length) {
            this.tab = 'speciality'
          }
          if (this.requirement.modelFacilities?.length) {
            this.tab = 'facility'
          }
          if (this.requirement.label?.name?.length) {
            this.tab = 'label'
          }
        }
      }
    },
  },
  methods: {
    async saveRequirement() {
      function getInteger(number) {
        if (!number || (typeof number === 'string' && !number.length)) {
          return null
        } else {
          return Math.round(number)
        }
      }
      const requirement = {
        minimum_users: getInteger(this.requirement.minimum_users),
        minimum_months: getInteger(this.requirement.minimum_months),
        minimum_percentage: getInteger(this.requirement.minimum_percentage),
        minimum_duration: getInteger(this.requirement.minimum_duration),
        group: getInteger(this.requirement.group),
        label: this.requirement.labelId,
        units: this.requirement.modelUnits?.map((unit) => {
          return { unit_id: unit.id }
        }),
        facilities: this.requirement.modelFacilities?.map((facility) => {
          return { facility_id: facility.id }
        }),
        rotation_types: this.requirement.rotation_types,
        specialities: this.requirement.modelSpecialities?.map((speciality) => {
          return { speciality_id: speciality.id }
        }),
      }

      if (this.requirement.id) {
        await this.$cms.request(
          this.$updateItem('schedule_requirements', this.requirement.id, requirement)
        )
      } else {
        requirement.target = { create: [{ collection: this.collection, item: { id: this.item } }] }
        await this.$cms.request(this.$createItem('schedule_requirements', requirement))
      }
      this.$emit('update:modelValue', null)
      this.$bus.$emit('update:' + this.collection + ':' + this.item)
    },

    updateLabel(value) {
      this.requirement.labelId = value?.id
      this.requirement.label = value
    },
    async deleteRequirement(requirement) {
      await this.$cms.request(this.$deleteItem('schedule_requirements', requirement.id))
      this.$bus.$emit('update:' + this.collection + ':' + this.item)
      this.$emit('update:modelValue', null)
    },
  },
}
</script>

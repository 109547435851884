<template>
  <v-navigation-drawer
    location="right"
    title="Elemente"
    order="3"
    color="white"
    class="flow-menu"
  >
    <v-container v-if="!readonly">
      <v-row>
        <v-col>
          <v-card
            class="text-center cursor-grab newunit"
            :draggable="true"
            color="success"
            variant="tonal"
            title="Startpunkt"
            data-cy="addStartPoint"
            icon-color="success"
            @dragstart="onDragStart($event, 'start', { type: 'external' })"
          >
            <template #prepend>
              <v-icon color="success">mdi-plus-circle-outline</v-icon>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            class="text-center cursor-grab newunit"
            :draggable="true"
            variant="tonal"
            title="Rotationsblock"
            data-cy="addRotationsblock"
            @dragstart="onDragStart($event, 'rotation')"
          >
            <template #prepend>
              <v-icon>mdi-plus-circle-outline</v-icon>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="proxy.getPremiumFeatures().includes('INTERNAL_ROTATION')">
        <v-col>
          <v-card
            class="text-center cursor-grab newunit"
            :draggable="true"
            color="primary"
            variant="tonal"
            title="Rotationsbereich"
            data-cy="addRotationRange"
            @dragstart="onDragStart($event, 'unit')"
          >
            <template #prepend>
              <v-icon color="primary">mdi-plus-circle-outline</v-icon>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            class="text-center cursor-grab newunit"
            :draggable="true"
            color="accent"
            variant="tonal"
            title="Rotation"
            data-cy="addExternalRotation"
            @dragstart="onDragStart($event, 'external')"
          >
            <template #prepend>
              <v-icon color="accent">mdi-plus-circle-outline</v-icon>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            class="text-center cursor-grab newunit"
            :draggable="true"
            color="success"
            variant="tonal"
            title="Ende"
            data-cy="addExternalRotation"
            @dragstart="onDragStart($event, 'end')"
          >
            <template #prepend>
              <v-icon color="success">mdi-plus-circle-outline</v-icon>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <template #append>
      <v-container class="mb-4 overflow-hidden height">
        <!--v-row>
          <v-col>
            <v-menu>
              <template #activator="{ props }">
                <v-btn
                  variant="flat"
                  v-bind="props"
                  ><span class="text-white">Log</span></v-btn
                >
              </template>

              <v-list>
                <v-list-item @click="$emit('log')">
                  <v-list-item-title>Log to console</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$emit('import')">
                  <v-list-item-title>Import from JSON</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row-->
        <v-row v-if="currentUser.main_facility_admin">
          <v-expansion-panels class="mb-4">
            <v-expansion-panel
              elevation="0"
              color="transparent"
            >
              <v-expansion-panel-title class="pa-4 bg-transparent">
                Punktevergabe konfigurieren
              </v-expansion-panel-title>
              <v-expansion-panel-text class="panel-text-dense">
                <div
                  style="max-height: 50vh"
                  class="overflow-y-auto overflow-x-hidden pa-2"
                >
                  <schedule-flow-sidebar-points-slider
                    v-for="key in Object.keys(mergedPoints)"
                    :key="'POINTS_' + key"
                    v-model="mergedPoints[key]"
                    :label="getReasonTitle(key)"
                    @change="savePoints(mergedPoints)"
                  />
                </div>
                <v-row
                  no-gutters
                  class="px-2 text-caption"
                >
                  <v-col> nicht wichtig </v-col>
                  <v-col>
                    <v-btn
                      variant="outlined"
                      @click="savePoints(defaultPoints)"
                      >Reset</v-btn
                    >
                  </v-col>
                  <v-col class="text-right"> wichtig </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              rounded
              block
              append-icon="mdi-chevron-right"
              size="large"
              data-cy="export"
              @click="$emit('export')"
            >
              Exportieren
            </v-btn>
          </v-col>

          <v-col cols="12">
            <app-auto-save-indicator
              :has-changes="saveLoading != null"
              :saving="saveLoading"
              block
            >
            </app-auto-save-indicator>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script setup>import { getCurrentInstance, onMounted } from 'vue'
const { proxy } = getCurrentInstance()

const app = useNuxtApp()

const defaultPoints = {
  flow_rotation: 5,
  flow_rotation_progress: 5,
  flow_rotation_prio: 5,
  flow_requirement: 5,
  regulation: 5,
  goals: 5,
  request: 5,
  request_history: 5,
  last_unit: 5,
  external: 5,
  last_external: 5,
  last_facility: 5,
  external_request: 5,
  new_competence: 5,
  extension: 5,
}

const mergePoints = (pointsToOverwrite) => {
  const points = { ...defaultPoints, ...pointsToOverwrite }
  if (!proxy.getPremiumFeatures().includes('INTERNAL_ROTATION')) {
    delete points.request
    delete points.request_history
    delete points.last_unit
    delete points.external
    delete points.last_external
  }
  if (!proxy.getPremiumFeatures().includes('COMPETENCES')) {
    delete points.new_competence
  }
  //remove extension because we set it to high always and for distribution planning it is not needed
  delete points.extension
  return points
}
const mergedPoints = ref(defaultPoints)

onMounted(async () => {
  const response = await app.$cms.request(
    app.$readItem('schedule_flow', props.scheduleFlowId, { fields: ['id', 'points'] })
  )
  mergedPoints.value = mergePoints(response.points)
})

function onDragStart(event, type, data) {
  if (event.dataTransfer) {
    event.dataTransfer.setData('application/vueflow/type', type)
    if (data) {
      event.dataTransfer.setData('application/vueflow/data', JSON.stringify(data))
    }
    event.dataTransfer.effectAllowed = 'move'
  }
}
const emit = defineEmits(['log', 'import', 'export', 'updateConfiguration'])
const props = defineProps({
  saveLoading: {
    type: Boolean,
    default: null,
  },
  readonly: {
    type: Boolean,
  },
  scheduleFlowId: {
    type: String,
    required: true,
  },
})

const savePoints = async (newPoints) => {
  const response = await app.$cms.request(
    app.$updateItem(
      'schedule_flow',
      props.scheduleFlowId,
      { points: newPoints },
      { fields: ['id', 'points'] }
    )
  )
  mergedPoints.value = mergePoints(response.points)
  emit('updateConfiguration')
}
</script>

<style lang="scss">
.newunit {
  border: 1px dashed;
}

.flow-menu {
  .v-card-item__content {
    text-align: left !important;
  }
  .v-card-title {
    font-size: 100%;
  }
}

.panel-text-dense .v-expansion-panel-text__wrapper {
  padding: 0px;
}
</style>

<template>
  <div
    class="dndflow"
    style="height: 90vh"
    data-cy="flowBoard"
    @drop="onDrop"
  >
    <v-progress-linear
      v-show="initLoading"
      color="primary"
      indeterminate
    />

    <VBtn
      style="position: absolute; left: 26px; bottom: 26px; z-index: 999"
      icon="mdi-format-list-checks"
      color="primary"
      variant="flat"
      @click="defaultHistorySidebar = !defaultHistorySidebar"
    />

    <VueFlow
      v-if="scheduleFlow.nodes.length || deleting"
      v-model:nodes="scheduleFlow.nodes"
      v-model:edges="scheduleFlow.edges"
      fit-view-on-init
      :max-zoom="scheduleFlow.nodes.length === 1 ? 1 : 3"
      :elements-selectable="!readonly"
      :nodes-connectable="!readonly"
      :nodes-draggable="!readonly"
      :edges-updatable="!readonly"
      @dragover="onDragOver"
    >
      <template #node-start="props">
        <div
          v-ripple="!readonly"
          @click="editNode = !readonly ? props : null"
        >
          <schedule-flow-node-start v-bind="props.data" />
        </div>
      </template>
      <template #node-rotation="props">
        <div
          v-ripple="!readonly"
          @click="editNode = !readonly ? props : null"
        >
          <schedule-flow-node-rotation
            v-bind="props.data"
            :initial-dimensions="props.dimensions"
            :validate="validateNode(props)"
            :elevation="
              intersectionIds?.includes(props.id) || editNode?.parent === props.id ? 6 : 0
            "
            @resize="onNodeUpdate(props)"
          />
        </div>
      </template>
      <template #node-unit="props">
        <div
          v-ripple="!readonly"
          @click="editNode = !readonly ? props : null"
        >
          <schedule-flow-node-unit v-bind="props.data" />
        </div>
      </template>
      <template #node-external="props">
        <div
          v-ripple="!readonly"
          @click="editNode = !readonly ? props : null"
        >
          <schedule-flow-node-external
            v-bind="props.data"
            :node-id="props.id"
          />
        </div>
      </template>
      <template #node-end="props">
        <div
          v-ripple="!readonly"
          @click="editNode = !readonly ? props : null"
        >
          <schedule-flow-node-end v-bind="props.data" />
        </div>
      </template>
      <template #edge-custom="props">
        <ScheduleFlowEdgeCustom
          v-bind="props"
          :readonly="readonly"
          @edit="editEdge = !readonly ? props : null"
        />
      </template>
      <MiniMap
        pannable
        zoomable
      />
    </VueFlow>

    <schedule-flow-sidebar-new
      v-if="scheduleFlow.id"
      :readonly="readonly"
      :schedule-flow-id="scheduleFlow.id"
      :save-loading="saveLoading"
      @log="logToConsole"
      @import="jsonDialog = true"
      @export="exportPdf"
      @update-configuration="loadDefaultHistory"
    />
    <schedule-flow-sidebar-edge
      v-model="editEdge"
      :facility-id="facilityId"
      @update:model-value="onEdgeUpdate(editEdge)"
      @delete="onEdgeDelete(editEdge)"
    ></schedule-flow-sidebar-edge>

    <schedule-flow-sidebar-node
      v-model="editNode"
      :facility-id="facilityId"
      @update:model-value="onNodeUpdate(editNode)"
      @update-unit="onUnitUpdate(editNode)"
      @delete="onNodeDelete(editNode)"
    ></schedule-flow-sidebar-node>

    <schedule-flow-sidebar-default-history
      ref="defaultHistorySidebarRef"
      v-model="defaultHistorySidebar"
      :facility-id="facilityId"
    ></schedule-flow-sidebar-default-history>

    <v-dialog
      v-model="jsonDialog"
      width="1024"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Aus JSON importieren</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="json"
                  label="JSON"
                  variant="outlined"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="importFromJson"
          >
            Importieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { VueFlow, useVueFlow, MarkerType } from '@vue-flow/core'
import { MiniMap } from '@vue-flow/minimap'
import '@vue-flow/minimap/dist/style.css'

import { nextTick, watch, ref } from 'vue'
import { useNotificationStore } from '~/store/notification'
const notificationStore = useNotificationStore()

const componentProps = defineProps({
  facilityId: {
    type: String,
    required: true,
  },
  readonly: {
    type: Boolean,
  },
})

const defaultHistorySidebar = ref(false)
const defaultHistorySidebarRef = ref(null)
const loadDefaultHistory = () => {
  defaultHistorySidebarRef.value.loadData()
}

const { $cms, $readItems, $createItem, $updateItem, $deleteItem, $deleteItems } = useNuxtApp()
const scheduleFlow = ref({
  nodes: [],
  edges: [],
})
const nodeFields = [
  'id',
  'type',
  'priority',
  'unit.id',
  'unit.short_name',
  'unit.long_name',
  'unit.specialities.id',
  'unit.specialities.speciality_id.id',
  'unit.specialities.speciality_id.name',
  'unit.blocked_for_maternity',
  'unit.type',
  'unit.minimum_vacancies',
  'unit.maximum_vacancies',
  'unit.rotation_types',
  'rotation_type',
  'parent_node',
  'position_x',
  'position_y',
  'facilities.id',
  'facilities.facility_id.id',
  'facilities.facility_id.name',
  'minimum_vacancies',
  'maximum_vacancies',
  'width',
  'height',
  'child_nodes',
]
const edgeFields = ['id', 'source', 'target']

const fields = [
  'id',
  ...nodeFields.map((f) => 'nodes.' + f),
  ...edgeFields.map((f) => 'edges.' + f),
]

function nodeFromDbToFlow(node) {
  let type = node.type
  let startType = null
  if (node.type === 'start_all') {
    type = 'start'
  } else if (node.type === 'start_external') {
    type = 'start'
    startType = 'external'
  }
  return {
    id: node.id,
    type: type,
    data: {
      type: startType,
      priority: node.priority,
      unit: node.unit,
      rotationType: node.rotation_type,
      facilities: node.facilities.map((facility) => {
        return facility.facility_id
      }),
      minimumVacancies: node.minimum_vacancies,
      maximumVacancies: node.maximum_vacancies,
    },
    parentNode: node.parent_node,
    position: { x: node.position_x, y: node.position_y },
    dimensions: { width: node.width, height: node.height },
    childNodes: node.child_nodes,
  }
}

function edgeFromDbToFlow(edge) {
  return {
    id: edge.id,
    type: 'custom',
    source: edge.source,
    target: edge.target,
    animated: true,
    markerEnd: MarkerType.ArrowClosed,
  }
}

function flowFromDb(flow) {
  scheduleFlow.value = {
    ...flow,
    nodes: flow.nodes.map((node) => nodeFromDbToFlow(node)),
    edges: flow.edges.map((edge) => edgeFromDbToFlow(edge)),
  }
}

function nodeFromFlowToDb(node) {
  let type = node.type
  if (node.type === 'start') {
    type = node.data.type === 'external' ? 'start_external' : 'start_all'
  }
  return {
    schedule_flow: scheduleFlow.value.id,
    type,
    priority: getInteger(node.data?.priority),
    unit: node.data?.unit?.id,
    rotation_type: node.data?.rotationType,
    parent_node: node.parentNode,
    position_x: getInteger(node.position?.x),
    position_y: getInteger(node.position?.y),
    width: getInteger(node.dimensions?.width),
    height: getInteger(node.dimensions?.height),
    facilities: node.data?.facilities?.map((facility) => {
      return { facility_id: facility.id }
    }),
    minimum_vacancies: getFloat(node.data?.minimumVacancies),
    maximum_vacancies: getFloat(node.data?.maximumVacancies),
  }
}
function edgeFromFlowToDb(edge) {
  return {
    schedule_flow: scheduleFlow.value.id,
    source: edge.source,
    target: edge.target,
  }
}

const saveLoading = ref(null)
const initLoading = ref(false)
onMounted(async () => {
  initLoading.value = true
  const flowResponse = await $cms.request(
    $readItems('schedule_flow', {
      filter: {
        facility: {
          _eq: componentProps.facilityId,
        },
      },
      fields,
      deep: {
        nodes: {
          facilities: {
            _filter: {
              facility_id: {
                _nnull: true,
              },
            },
          },
        },
      },
    })
  )
  if (flowResponse.length) {
    const flow = flowResponse[0]
    flowFromDb(flow)
  } else {
    const flow = await $cms.request(
      $createItem(
        'schedule_flow',
        {
          facility: componentProps.facilityId,
          nodes: [
            {
              type: 'start_all',
              position_x: 100,
              position_y: 20,
            },
          ],
        },
        {
          fields,
        }
      )
    )
    flowFromDb(flow)
  }
  initLoading.value = false
})

const deleting = ref(false)

const editNode = ref(null)
const editEdge = ref(null)

const {
  findNode,
  onConnect,
  addEdges,
  addNodes,
  removeEdges,
  removeNodes,
  project,
  vueFlowRef,
  getIntersectingNodes,
  onNodeDragStart,
  onNodeDrag,
  onNodeDragStop,
} = useVueFlow()

function onNodeUpdate(node) {
  if (node) {
    if (node) {
      node.parentNode = node.parent
    }
    updateNode(node)
  }
}

function onUnitUpdate(node) {
  if (node.type === 'unit') {
    scheduleFlow.value.nodes
      .filter((n) => n.data?.unit?.id === node.data?.unit?.id)
      .forEach((n) => (n.data.unit = node.data?.unit))
  }
}

function onEdgeUpdate(edge) {
  if (edge) {
    const oldEdges = scheduleFlow.value.edges.filter((oldEdge) => oldEdge.id !== edge.id)
    oldEdges.push(edge)
    scheduleFlow.value.edges = oldEdges
  }
}

function onNodeDelete(node) {
  editNode.value = null
  deleteNode(node)
}

function onEdgeDelete(edge) {
  editEdge.value = null
  deleteEdge(edge)
}

function onDragOver(event) {
  event.preventDefault()

  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'move'
  }
}

const intersectionIds = ref([])
const oldPosition = ref({ x: 0, y: 0 })

onNodeDragStart((event) => {
  intersectionIds.value = []
  if (event.node) {
    oldPosition.value = event.node.position
  }
})

onNodeDrag(({ node: draggedNode }) => {
  const intersections = getIntersectingNodes(draggedNode)
  intersectionIds.value = intersections.map((intersection) => intersection.id)
})

onNodeDragStop((event) => {
  intersectionIds.value = []
  if (event.node) {
    updateNode(event.node)
  }
})

onConnect((params) => {
  const { source, sourceHandle, target, targetHandle } = params
  // check if edge was drawn from out- to in-handle
  if (sourceHandle !== 'b' || targetHandle !== 'a') {
    notificationStore.set({
      title: 'Fehler',
      text: 'Verbindung kann nur zwischen Ausgang und Eingang der Blöcke gezogen werden.',
      type: 'error',
    })
    return
  }

  // check if an edge already exists between the source and target
  const duplicateEdge = scheduleFlow.value.edges.some(
    (edge) =>
      (edge.source === source && edge.target === target) ||
      (edge.source === target && edge.target === source)
  )
  if (duplicateEdge) {
    notificationStore.set({
      title: 'Fehler',
      text: 'Diese Verbindung existiert bereits.',
      type: 'error',
    })
    return
  }

  const edge = {
    ...params,
    type: 'custom',
    animated: true,
    markerEnd: MarkerType.ArrowClosed,
  }
  createEdge(edge)
})

function onDrop(event) {
  const id = 'new-node'
  const type = event.dataTransfer?.getData('application/vueflow/type')
  const data = event.dataTransfer?.getData('application/vueflow/data')

  if (type) {
    const { left, top } = vueFlowRef.value.getBoundingClientRect()

    const position = project({
      x: event.clientX - left,
      y: event.clientY - top,
    })

    const newNode = {
      id: id,
      type,
      data: data ? JSON.parse(data) : {},
      position,
      dimensions: { width: 1, height: 1 },
    }

    addNodes([newNode])

    // check if node was dropped in rotation node
    let rotationNode = null
    if (newNode.type === 'unit' || newNode.type === 'external') {
      const nodes = getIntersectingNodes(newNode)
      rotationNode = nodes.find((node) => node.type === 'rotation')
      if (!rotationNode) {
        notificationStore.set({
          title: 'Fehler',
          text: 'Kann nur in Rotationsblock gezogen werden',
          type: 'error',
        })
        removeNodes(newNode.id)
        return
      } else {
        const node = findNode(newNode.id)
        node.parentNode = rotationNode.id
      }
    }

    // align node position after drop, so it's centered to the mouse
    nextTick(() => {
      const node = findNode(newNode.id)
      const stop = watch(
        () => node.dimensions,
        async (dimensions) => {
          if (dimensions.width > 0 && dimensions.height > 0) {
            if (rotationNode) {
              node.position = {
                x: node.position.x - rotationNode.position.x - node.dimensions.width / 2,
                y: node.position.y - rotationNode.position.y - node.dimensions.height / 2,
              }
            } else {
              node.position = {
                x: node.position.x - node.dimensions.width / 2,
                y: node.position.y - node.dimensions.height / 2,
              }
            }
            // remove temp node and add node from database
            removeNodes(node.id)
            const createdNode = await createNode(node)

            // open sidebar
            if (
              createdNode.type === 'unit' ||
              createdNode.type === 'external' ||
              createdNode.type === 'end'
            ) {
              editNode.value = createdNode
            }
            stop()
          }
        },
        { deep: true, flush: 'post' }
      )
    })
  }
}

// methods for saving to database
function getInteger(number) {
  if (!number || (typeof number === 'string' && !number.length)) {
    return null
  } else {
    return Math.round(number)
  }
}
function getFloat(number) {
  if (!number || (typeof number === 'string' && !number.length)) {
    return null
  } else {
    return Math.round(number * 10) / 10
  }
}

async function createNode(node) {
  saveLoading.value = true
  const nodeResponse = await $cms.request(
    $createItem('schedule_flow_node', nodeFromFlowToDb(node), { fields: nodeFields })
  )
  const createdNode = nodeFromDbToFlow(nodeResponse)
  addNodes(createdNode)

  updateRotationBlockWithChild(createdNode.parentNode, createdNode.id)
  loadDefaultHistory()
  saveLoading.value = false
  return createdNode
}

function updateRotationBlockWithChild(parentNodeId, childNodeId, add = true) {
  const rotationBlock = scheduleFlow.value.nodes.find(
    (block) => block.type === 'rotation' && block.id === parentNodeId
  )

  if (rotationBlock) {
    if (add) {
      // Add the new node to the childNodes array of the rotation block
      rotationBlock.childNodes.push(childNodeId)
    } else {
      // Remove the child node from the rotation block's childNodes array
      rotationBlock.childNodes = rotationBlock.childNodes.filter((id) => id !== childNodeId)
    }
  }
}

async function updateNode(node) {
  saveLoading.value = true
  // check if node was dropped in rotation node
  const oldRotationNode = scheduleFlow.value.nodes.find((block) => block.id === node.parentNode)
  if (node.type === 'unit' || node.type === 'external') {
    const nodes = getIntersectingNodes(node)
    const rotationNode = nodes.find((n) => n.type === 'rotation')
    if (!rotationNode) {
      node.position = oldPosition

      notificationStore.set({
        title: 'Fehler',
        text: 'Kann nur in Rotationsblock gezogen werden',
        type: 'error',
      })
    } else if (oldRotationNode && rotationNode.id !== oldRotationNode.id) {
      node.parentNode = rotationNode.id
      node.position = {
        x: node.position.x + oldRotationNode.position.x - rotationNode.position.x,
        y: node.position.y + oldRotationNode.position.y - rotationNode.position.y,
      }
    }
  }

  const nodeResponse = await $cms.request(
    $updateItem('schedule_flow_node', node.id, nodeFromFlowToDb(node), { fields: nodeFields })
  )
  const oldNodes = scheduleFlow.value.nodes.filter((oldNode) => oldNode.id !== node.id)
  const updatedNode = nodeFromDbToFlow(nodeResponse)
  updatedNode.data.unit = node.data.unit
  oldNodes.push(updatedNode)
  scheduleFlow.value.nodes = oldNodes
  loadDefaultHistory()

  saveLoading.value = false
  return updatedNode
}

async function deleteNode(node) {
  saveLoading.value = true
  deleting.value = true
  const childNodes = scheduleFlow.value.nodes.filter((n) => n.parentNode === node.id)
  if (childNodes.length) {
    const childNodeIds = childNodes.map((n) => n.id)
    await $cms.request($deleteItems('schedule_flow_node', childNodeIds))
    await removeNodes(childNodeIds)
  }
  updateRotationBlockWithChild(node.parent, node.id, false)

  await $cms.request($deleteItem('schedule_flow_node', node.id))
  await removeNodes(node.id)
  if (!scheduleFlow.value.nodes.length) {
    await createNode({
      type: 'start',
      position: {
        x: node.position.x,
        y: node.position.y,
      },
      data: {},
    })
  }
  deleting.value = false
  loadDefaultHistory()
  saveLoading.value = false
  return true
}

async function createEdge(edge) {
  saveLoading.value = true
  const edgeResponse = await $cms.request(
    $createItem('schedule_flow_edge', edgeFromFlowToDb(edge), { fields: edgeFields })
  )
  const createdEdge = edgeFromDbToFlow(edgeResponse)
  addEdges(createdEdge)
  loadDefaultHistory()
  saveLoading.value = false
  return createdEdge
}

async function deleteEdge(edge) {
  saveLoading.value = true
  await $cms.request($deleteItem('schedule_flow_edge', edge.id))
  removeEdges(edge.id)
  loadDefaultHistory()
  saveLoading.value = false
  return true
}

function isRotationBlockEmpty(rotationBlockId) {
  const rotationBlock = scheduleFlow.value.nodes.find(
    (node) => node.id === rotationBlockId && node.type === 'rotation'
  )
  return !rotationBlock.childNodes || rotationBlock.childNodes.length === 0
}

function isNodeConnectedToStart(nodeId) {
  // Initialize a set to keep track of visited nodes
  const visited = new Set()

  // Initialize a stack to keep track of nodes to visit
  const nodesToVisit = [nodeId]

  // Loop until there are nodes to visit
  while (nodesToVisit.length > 0) {
    // Pop the last node from the stack
    const currentNodeId = nodesToVisit.pop()
    // Find the current node in the graph
    const currentNode = scheduleFlow.value.nodes.find((node) => node.id === currentNodeId)
    // Check if the current node is the start node
    if (currentNode.type === 'start') {
      return true
    }

    // Mark the current node as visited
    visited.add(currentNodeId)

    // Find connected nodes where the current node is the source
    const sourceConnectedNodes = scheduleFlow.value.edges.filter(
      (edge) => edge.source === currentNodeId
    )
    for (const connectedNode of sourceConnectedNodes) {
      // Check if the connected node has not been visited
      if (!visited.has(connectedNode.target)) {
        // Add the connected node to the nodes to visit
        nodesToVisit.push(connectedNode.target)
      }
    }

    // Find connected nodes where the current node is the target
    const targetConnectedNodes = scheduleFlow.value.edges.filter(
      (edge) => edge.target === currentNodeId
    )
    for (const connectedNode of targetConnectedNodes) {
      // Check if the connected node has not been visited
      if (!visited.has(connectedNode.source)) {
        // Add the connected node to the nodes to visit
        nodesToVisit.push(connectedNode.source)
      }
    }
  }

  return false
}

function validateNode(node) {
  const rotationBlockEmpty = isRotationBlockEmpty(node.id)
  const connectedToStart = isNodeConnectedToStart(node.id)
  return connectedToStart && !rotationBlockEmpty
}

// import/export
const jsonDialog = ref(false)
const json = ref('')
function logToConsole() {
  console.log(JSON.stringify(scheduleFlow.value))
}
function importFromJson() {
  scheduleFlow.value = JSON.parse(json.value)
  jsonDialog.value = false
}
function exportPdf() {
  window.open('/schedule/flow/printflow?facilityId=' + componentProps.facilityId, '_blank')
}
</script>

<style>
@import '@vue-flow/core/dist/style.css';
@import '@vue-flow/core/dist/theme-default.css';
</style>
